import { richText } from '../../utils/html';
import { getGuides } from '@/slices/AllGuides/adapter';
import { cardGuideAdapter } from '@/utils/helpers/get-cards';
import { getInsuranceRoot } from '~/config/routes';
import { capitalizeFirstLetter } from '~/utils/text';

export default async (
  { primary },
  { pageData, page, i18n, prismic, enhancedLinkSerializer },
) => {
  const offerId = pageData?.offer?.id || null;
  const lang = i18n.localeProperties.value.language;
  const guidesPerOffer = await getGuides(
    offerId,
    prismic,
    lang,
    pageData.isPro || false,
    page,
    16,
  );

  const offerTitle = pageData.offer.data
    ? pageData.offer.data.title
    : capitalizeFirstLetter(getInsuranceRoot(lang));

  return {
    head: {
      title: richText(primary.title),
    },
    items: {
      withCarousel: false,
      totalPages: guidesPerOffer.totalPages,
      cards: guidesPerOffer.guides.map((guide) =>
        cardGuideAdapter({
          editoCarouselCard: {
            ...guide,
            link_type: 'Document',
            offerTitle,
          },
          cardType: 'guide',
          i18n,
          enhancedLinkSerializer,
        }),
      ),
    },
  };
};
